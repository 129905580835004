import React, { Component } from 'react';
import {
  TransferSummeryProductFilter,
  TransferSummeryProductTable
} from './components';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  colors,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination
} from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender';
import { utcTimeToFullDate } from '../../constants/function/formatConverter';
import TableRender from '../../components/TableRender/TableRender';
import ServicesTranferSummery from '../../services/servicesTranferSummery';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ServicesCampaign from '../../services/servicesCampaign';
import {
  currencyFormat,
  getThaiYearOption,
  getCurrentYearFormat
} from '../../constants/function/formatConverter';
import { getExistData } from '../../constants/function/checkDataValue';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { DialogImport } from '../../components/DialogAlert/DialogImport';
import ConfirmTransferTrade from './ConfirmTransferTrade';
import ConfirmRevokeApprovalSales from './ConfirmRevokeApprovalSales';
import { ButtonRender } from '../../components/ButtonRender/ButtonRender';
import * as XLSX from 'xlsx';
import { Empty } from 'antd';
import { ExitStatus } from 'typescript';
import { isArray } from 'validate.js';
import data from 'views/Dashboard/components/LatestOrders/data';
import moment from 'moment';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class TransferSummeryTrade extends Component {
  state = {
    filterList: [
      {
        name: 'campaignId',
        displayName: 'แคมเปญ',
        showDisplayName: true,
        type: 'option',
        value: '',
        option: [],
        required: false,
        widthCol: 6,
        styleXs: {
          label: 12,
          input: 12
        },
        styleSm: {
          label: 3,
          input: 9
        }
      }
    ],
    TABLE_CONTENT: [],
    ROW_CONTENT: [],
    rowNumSelected: [],
    rowNumSelectedData: [],
    rowCount: 0,
    page: 0,
    rowsPerPage: 10,
    FOOTER_CONTENT: {},
    alertMessage: {
      content: '',
      open: false,
      status: ''
    },
    openConfirm: false,
    openConfirmRevoke: false,
    itemImport: {
      fileName: '-',
      statusName: '-',
      status: false,
      remark: '-',
      campaignName: '',
      amount: ''
    },
    groupNo: '',
    pcNo: '',
    dataParse: ['no', 'pcNo', 'pcName', 'qty', 'amount'],
    openImport: false,
    openConfirmImport: false,
    //excelHeader: ['no', 'pcNo', 'pcName', 'branchName']
    excelHeader: ['no', 'pcNo', 'pcName', 'qty', 'amount'],
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    monthYear: null
  };

  componentDidMount() {
    this.getCampaign();
    // this.searchSubmit()
  }

  handleUpload = e => {
    e.preventDefault();
    let files = e.target.files,
      f = files[0];
    let reader = new FileReader();
    reader.onload = e => {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // setFileUploaded(dataParse);
      this.setState({ dataParse: dataParse }, () =>
        this.checkDataExcel(this.state.dataParse)
      );
    };
    reader.readAsBinaryString(f);
    let { itemImport } = this.state;
    itemImport.fileName = e.target.files[0].name;
    this.setState({ itemImport });
  };

  checkDataExcel = excel => {
    let dataExcel = [];
    excel.forEach(value => {
      if (value.length > 0) {
        dataExcel.push(value);
      }
    });

    let { itemImport } = this.state;
    const allLength = dataExcel.length - 1;
    const stateLength = this.state.excelHeader.length;
    const excelLength = dataExcel[0].length;
    let equalColumn = undefined;
    let sameColumn = undefined;

    //เช็คจำนวนคอลัมว่าเท่ากันไหม
    if (stateLength === excelLength) {

      equalColumn = true;
      //เช็คว่าชื่อคอลัมเรียงเหมือนกัน และชื่อเหมือนกันไหม
      for (let i = 0; i < stateLength; i++) {
        if (this.state.excelHeader[i] === excel[0][i]) {
          sameColumn = true;
        } else if (this.state.excelHeader[i] !== excel[0][i]) {
          sameColumn = false;
          itemImport.statusName = 'ไม่สามารถนำเข้าข้อมูลได้';
          itemImport.status = false;
          itemImport.remark = 'หัวชื่อ Column ไม่ถูกต้อง';
          break;
        }
      }

      if (sameColumn === true) {
        //เช็คว่ามีช่องไหนว่างรึเปล่า

        if (equalColumn === true && sameColumn === true) {

          if (dataExcel[1].length === 0) {
            itemImport.statusName = 'ไม่สามารถนำเข้าข้อมูลได้';
            itemImport.status = false;
            itemImport.remark = 'พบ Field ที่ข้อมูลว่างเปล่า';
          } else {
            dataExcel.forEach(field => {
              if (field.length !== 5) {
                itemImport.statusName = 'ไม่สามารถนำเข้าข้อมูลได้';
                itemImport.status = false;
                itemImport.remark = 'พบ Field ที่ข้อมูลว่างเปล่า';
              } else {
                itemImport.statusName = 'สามารถนำเข้าข้อมูลได้';
                itemImport.status = true;
                itemImport.remark = `มีจำนวน ${allLength} รายการ`;
                itemImport.amount = allLength;
              }
            });
          }
        }
      }
    } else if (stateLength !== excelLength) {
      equalColumn = false;
      itemImport.statusName = 'ไม่สามารถนำเข้าข้อมูลได้';
      itemImport.status = false;
      itemImport.remark = 'จำนวน Column ไม่ถูกต้อง';
    }
    this.setState({ itemImport });
  };

  handleChangeCampaignImport = (e, ref) => {

    let { itemImport } = this.state;
    if (ref === 'dataImport') {
      itemImport.campaignName = e.label;
      this.setState({ campaignId: e.value });
    } else if (ref === 'monthYearImport') {
      this.setState({ monthYear: e });
    }

    let filterList = [...this.state.filterList];

    filterList.map((val, i) => {
      val.value = e.value;
    });

    this.setState({ itemImport, filterList: filterList });
  };

  getCampaign = () => {
    ServicesCampaign.getCampaign({
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      let filterList = [...this.state.filterList];
      let campaignOption = [];

      switch (res.status) {
        case 200:
          res.data.result.map((val, i) => {
            campaignOption.push({
              label: val.campaignName,
              value: val.campaignId
            });
          });

          filterList[0].option = campaignOption;
          this.setState({ filterList: filterList });
          break;
        default:
          filterList[0].option = [];
          this.setState({ filterList: filterList });
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  getWaitSelect = data => {
    ServicesTranferSummery.getWaitSelect(data).then(res => {
      switch (res.status) {
        case 200:
          let TABLE_CONTENT = [];
          let ROW_CONTENT = [];

          let res2 = res.data.result.data;
          let rowCount = 0;
          res2.map((val, i) => {
            rowCount = ++i;
            TABLE_CONTENT.push({
              checkbox: val,
              rowNo: val.rowNo,
              groupNo: val.groupName,
              pcName: val.pcName,
              shopNo: val.shopName,
              qty: val.qty,
              amount: val.amount,
              // action: (
                // <ButtonRender
                //   type="warning"
                //   text="ตีกลับอนุมัติการขาย"
                //   onClick={() =>
                //     this.setState({
                //       openConfirmRevoke: true,
                //       groupNo: val.groupNo,
                //       pcNo: val.pcNo
                //     })
                //   }
                // ></ButtonRender>
              // )
            });
            ROW_CONTENT.push(val);
          });
          this.setState({
            TABLE_CONTENT: TABLE_CONTENT,
            ROW_CONTENT: ROW_CONTENT,
            rowCount: rowCount,
            FOOTER_CONTENT: res.data.result.footer
          });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  sendForApproval = data => {
    ServicesTranferSummery.sendForApproval(data).then(res => {
      switch (res.status) {
        case 200:
          // todo next...
          this.setState({
            alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
          });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  handleCheckbox = (selected, value) => {
    let rowNumSelected = this.state.rowNumSelected;
    let rowNumSelectedData = this.state.rowNumSelectedData;
    if (rowNumSelected.includes(selected)) {
      //เอาออก
      // rowNumSelected.remove(selected)
      rowNumSelected = rowNumSelected.filter((val, i) => {
        return selected !== val;
      });
      rowNumSelectedData = rowNumSelectedData.filter((val, i) => {
        return val.rowNo !== selected;
      });
    } else {
      //เอาเข้า
      rowNumSelected.push(selected);
      rowNumSelectedData.push(value);
    }
    this.setState({
      rowNumSelected: rowNumSelected,
      rowNumSelectedData: rowNumSelectedData
    });
  };

  handleCheckboxAll = e => {
    let ROW_CONTENT = this.state.ROW_CONTENT;
    let rowNumSelected = this.state.rowNumSelected;
    let rowNumSelectedData = this.state.rowNumSelectedData;

    if (e.target.checked) {
      ROW_CONTENT.map((val, i) => {
        if (!rowNumSelected.includes(val.rowNo)) {
          rowNumSelected.push(val.rowNo);
          rowNumSelectedData.push(val);
        }
      });
    } else {
      // ROW_CONTENT.map((val, i) => {
      rowNumSelected = [];
      rowNumSelectedData = [];
      // })
    }
    this.setState({ rowNumSelected, rowNumSelected, rowNumSelectedData });
  };

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];

    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          // for uploadContent filter
          value = [];
        }
        val.value = value;
      }
    });
    this.setState({ filterList: filterList });
  };

  searchSubmit = () => {
    let object = {};
    // const { campaignId } = this.state;
    let isValid = false;

    this.state.filterList.map((val, i) => {
      if (val.value !== '') {
        object[`${val.name}`] = val.value;
        isValid = true;
      }
    });

    if (isValid) {
      object.userLogin = JSON.parse(localStorage.getItem('userData')).userId;
      object.roleId = JSON.parse(localStorage.getItem('userData')).roleId;
      object.dateTo = '';
      object.dateFrom = '';
      this.getWaitSelect(object);
    } else {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณาเลือกแคมเปญ')
      });
    }
  };

  importFileIncentive = () => {
    const { dataParse, userLogin, campaignId, monthYear } = this.state;
    let data = [];
    let day = moment(monthYear, 'YYYY/MM/DD');
    const month = day.format('M');
    const year = day.format('YYYY');

    for (const i in dataParse) {
      if (
        i > 0 &&
        !!dataParse[i][1] &&
        !!dataParse[i][3] &&
        !!dataParse[i][4]
      ) {
        data.push({
          pcNo: dataParse[i][1],
          qty: dataParse[i][3],
          amount: dataParse[i][4]
        });
      }
    }
    let obj = { userLogin, campaignId, month, year, data };

    ServicesTranferSummery.importFileIncentive(obj).then(res => {
      switch (res.status) {
        case 200:
          // todo next...
          this.setState(
            {
              alertMessage: callDialogAlert('success', 'import สำเร็จ'),
              openImport: false,
              openConfirmImport: false,
              monthYear: new Date()
            },
            () => this.searchSubmit()
          );
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert('error', 'import ไม่สำเร็จ'),
            openImport: false,
            openConfirmImport: false,
            monthYear: new Date()
          });
      }
    });
  };

  renderTableContent = content => {
    let td = Object.keys(content);

    return td.map((val, i) => {
      return val === 'checkbox' ? (
        <TableCell className="pointer" style={{ textAlign: 'center' }} key={i}>
          <React.Fragment>
            <Checkbox
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={this.state.rowNumSelected.includes(content[val].rowNo)}
              onChange={() =>
                this.handleCheckbox(content[val].rowNo, content[val])
              }
              inputProps={{ 'aria-label': 'labelId' }}
            />
          </React.Fragment>
        </TableCell>
      ) : (
        <TableCell className="pointer" style={{ textAlign: 'center' }} key={i}>
          {content[val]}
        </TableCell>
      );
    });
  };

  handlePageChange = (event, page) => {
    this.setState({ page: page });
  };

  handleRowsPerPageChange = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSubmit = () => {
    let object = {};
    let rowNumSelectedData = this.state.rowNumSelectedData;

    this.state.filterList.map((val, i) => {
      object[`${val.name}`] = val.value;
    });

    object.userLogin = JSON.parse(localStorage.getItem('userData')).userId;
    object.roleId = JSON.parse(localStorage.getItem('userData')).roleId;
    object.dateTo = '';
    object.dateFrom = '';
    object.data = [];

    rowNumSelectedData.map((val, i) => {
      object.data.push({
        pcNo: val.pcNo,
        pcName: val.pcName,
        groupNo: val.groupNo,
        groupName: val.groupName,
        shopNo: val.shopNo,
        shopName: val.shopName,
        qty: val.qty,
        amount: val.amount
      });
    });
    this.setState({ alertMessage: callDialogAlert('processing', '') });

    this.sendForApproval(object);
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      // this.handleBack()
      this.searchSubmit();
    }

    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      },
      rowNumSelected: [],
      rowNumSelectedData: []
    });
  };

  handleOpenConfirm = () => {
    let isValid = true;

    this.state.filterList.map((val, i) => {
      if (val.value === '') {
        isValid = false;
      }
    });

    if (isValid && this.state.rowNumSelectedData.length > 0) {
      this.setState({ openConfirm: true });
    } else {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณาเลือกรายการ')
      });
    }
  };

  handleCloseConfirm = () => {
    // this.searchSubmit()
    this.setState({ openConfirm: false });
  };

  handleRevokeApprovalSales = () => {
    const { groupNo, pcNo } = this.state;
    let object = {
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: 1,
      groupNo,
      pcNo
    };

    this.state.filterList.map((val, i) => {
      object[`${val.name}`] = val.value;
    });

    ServicesTranferSummery.revokeApprovalSales(object).then(res => {
      switch (res.status) {
        case 200:
          this.setState({
            alertMessage: callDialogAlert('success', 'ดำเนินการสำเร็จ')
          });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  handleOpenImport = () => {
    this.setState({ openImport: true });
    this.setState({
      itemImport: {
        ...this.state.itemImport,
        fileName: '-',
        statusName: '-',
        status: false,
        remark: '-',
        campaignName: '',
        amount: ''
      }
    });
  };
  handleOpenConfirmImportModal = () => {
    this.setState({ openConfirmImport: true });
  };

  handleCloseImport = () => {
    this.setState({ openImport: false, monthYear: null });
  };

  handleCloseConfirmImportModal = () => {
    this.setState({ openConfirmImport: false });
  };

  render() {
    const { classes } = this.props;
    const {
      filterList,
      TABLE_CONTENT,
      rowsPerPage,
      page,
      alertMessage,
      dataParse
    } = this.state;

    let TABLE_HEADER = [
      {
        key: 'checkbox',
        label: (
          <Checkbox
            indeterminate={
              this.state.rowNumSelected.length > 0 &&
              this.state.rowNumSelected.length < this.state.rowCount
            }
            checked={
              this.state.rowCount > 0 &&
              this.state.rowNumSelected.length === this.state.rowCount
            }
            onChange={e => this.handleCheckboxAll(e)}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        )
      },
      {
        key: 'rowNo',
        label: 'ลำดับ'
      },
      {
        key: 'groupNo',
        label: 'กลุ่มลูกค้า'
      },
      {
        key: 'pcName',
        label: 'ชื่อ-นามสกุล'
      },
      {
        key: 'shopName',
        label: 'ชื่อร้าน'
      },
      {
        key: 'qty',
        label: 'จำนวนที่ขาย'
      },
      {
        key: 'amount',
        label: 'จำนวนเงิน'
      },
      {
        key: 'action',
        label: ''
      }
    ];

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Card>
            <CardHeader
              action={
                <Button
                  color="primary"
                  style={{ backgroundColor: '#d81b60' }}
                  // disabled={!formState.isValid}
                  // fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => this.handleOpenImport()}>
                  import
                </Button>
              }
              title={'สรุปยอดโอน'}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <FilterRender
                  filterList={filterList}
                  handleChangeFilter={this.handleChangeFilter}
                  //hasError={this.hasError}
                  //helperTextError={this.helperTextError}
                />
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ textAlignLast: 'right' }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => this.searchSubmit()}>
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          &nbsp;
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                {/* <CardHeader
									// action={
									// 	<Button
									// 		color="secondary"
									// 		// disabled={!formState.isValid}
									// 		// fullWidth
									// 		size="large"
									// 		type="submit"
									// 		variant="contained"
									// 		onClick={() => this.handleRevokeApprovalSales()}
									// 	>
									// 		ตีกลับอนุมัติบันทึกการขาย
									// </Button>
									// }
								// title={
								// 	"สรุปยอดโอน"
								// }
								/>
								<Divider /> */}
                <CardContent>
                  <React.Fragment>
                    <PerfectScrollbar>
                      <div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {TABLE_HEADER.map((val, i) => {
                                return (
                                  <TableCell
                                    style={{ textAlign: 'center' }}
                                    key={i}>
                                    {val.label}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {TABLE_CONTENT.length > 0 &&
                              TABLE_CONTENT.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              ).map((val, i) => {
                                return (
                                  <TableRow hover key={i}>
                                    {this.renderTableContent(val)}
                                  </TableRow>
                                );
                              })}
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                style={{ textAlign: 'right' }}>
                                รวม
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {currencyFormat(
                                  getExistData(
                                    this.state.FOOTER_CONTENT.totalQty
                                  )
                                )}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {currencyFormat(
                                  getExistData(
                                    this.state.FOOTER_CONTENT.totalAmount
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </PerfectScrollbar>
                    <div>
                      <TablePagination
                        component="div"
                        count={TABLE_CONTENT.length}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={this.handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      />
                    </div>
                  </React.Fragment>
                </CardContent>
                <Divider />
              </Card>
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ textAlign: 'center' }}>
              <Button
                style={{ backgroundColor: colors.green[600], color: 'white' }}
                variant="contained"
                onClick={() => this.handleOpenConfirm()}>
                ส่งขออนุมัติ
              </Button>
            </Grid>
            <DialogAlert data={alertMessage} close={this.closeAlert} />
          </Grid>
          <ConfirmTransferTrade
            handleSubmit={this.handleSubmit}
            open={this.state.openConfirm}
            close={this.handleCloseConfirm}
          />
          <ConfirmRevokeApprovalSales
            handleSubmit={this.handleRevokeApprovalSales}
            open={this.state.openConfirmRevoke}
            close={() => this.setState({ openConfirmRevoke: false })}
          />
        </div>
        <DialogImport
          item={this.state.itemImport}
          open={this.state.openImport}
          close={this.handleCloseImport}
          filterList={filterList}
          actionUpload={this.handleUpload}
          openConfirm={this.state.openConfirmImport}
          openConfirmModal={this.handleOpenConfirmImportModal}
          closeConfirm={this.handleCloseConfirmImportModal}
          changeCampaignImport={this.handleChangeCampaignImport}
          importIncentive={this.importFileIncentive}
          dataPreview={dataParse}
          monthYear={this.state.monthYear}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(TransferSummeryTrade);
